<template>
    <div>
        <!-- App Capsule -->
        <div id="appCapsule">

            <!--            <div class="message-divider">-->
            <!--                Friday, Sep 20, 10:40 AM-->
            <!--            </div>-->
            <template v-for="(message, idx) in messages">

                <div class="message-item mt-2" :key="'msg_'+idx" v-if="message.data.from_admin">
                    <img src="../assets/img/metriclogo_color.png" alt="avatar" class="avatar">
                    <div class="content">
                        <div class="title">
                            RE support
                        </div>
                        <div class="bubble">
                            {{message.data.content}}
                        </div>
                        <div class="footer">{{getReadableDatetime(message.data.created_at)}}</div>
                    </div>
                </div>

                <div class="message-item user" :key="'msg_'+idx" v-else>
                    <div class="content">
                        <div class="bubble">
                            {{message.data.content}}
                        </div>
                        <div class="footer">{{getReadableTime(message.data.created_at)}}</div>
                    </div>
                </div>

            </template>

            <div class="col-12 text-center mt-5" v-if="messages.length <= 0">
                <p>Empty conversation</p>
            </div>

        </div>
        <!-- * App Capsule -->

        <!-- chat footer -->
        <support-message/>
        <!-- * chat footer -->
    </div>
</template>

<script>
import supportMessage from "../components/supportMessage";
import {mapGetters} from 'vuex';
import methodsMixin from "../utils/methodsMixin";

export default {
    name: "Support",
    computed: {
        ...mapGetters('user', {
            user: 'getUser'
        }),
        ...mapGetters('support', {
            messages: 'getMessages'
        })
    },
    mixins: [methodsMixin],
    methods: {
        async fetchUnreadSupportMessges(){
            let response = await this.$store.dispatch('support/getUnreadMessages');
            if(response.status){
                this.$emit('msgloaded', response.data);
            }
        }
    },
    components: {
        supportMessage
    },
    mounted() {
        this.$store.dispatch('support/fetchUserMessages');
        this.$store.dispatch('support/markMsgAsRead', this.$store.getters['user/getUser'].id)
        .then(()=>{
            this.fetchUnreadSupportMessges()
        });
    }
}
</script>

<style scoped>
.avatar{
    width: 30px;
    height: 20px;
    border-radius: 0;
}
#appCapsule{
    max-height: 450px;
    overflow: hidden scroll;
}
</style>