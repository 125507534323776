<template>
    <div class="chatFooter">
        <form @submit.prevent="send">
            <!--            <a href="javascript:void(0);" class="btn btn-icon btn-text-secondary rounded">-->
            <!--                <ion-icon name="camera"></ion-icon>-->
            <!--            </a>-->
            <div class="form-group basic">
                <div class="input-wrapper">
                    <textarea v-model="message.content" class="form-control" placeholder="Aa"></textarea>
                </div>
            </div>
            <button type="submit" class="btn btn-icon btn-primary rounded">
                <ion-icon name="arrow-forward-outline"></ion-icon>
            </button>
        </form>
    </div>
</template>

<script>
import {SupportModel} from "../models/support";

export default {
    name: "supportMessage",
    data(){
        return {
            message: new SupportModel(),
            semaphore: 1
        }
    },
    methods: {
        resetMessage() {
            this.message = new SupportModel();
            this.message.from = this.$store.getters['user/getUser'].id;
        },
        async send(){
            if(this.message.content && this.semaphore === 1){
                this.semaphore = 0;
                const response = await this.$store.dispatch('support/sendUserMessage', this.message);
                if(response.status){
                    this.resetMessage();
                }else{
                    return this.$store.dispatch('notification/display_noti', {
                        message: response.message,
                    })
                }
                this.semaphore = 1;
            }
        }
    },
    mounted() {
        this.resetMessage();
    }
}
</script>

<style scoped>
.chatFooter{
    bottom: 50px
}
</style>